import React from "react";
import "./Button.css";
import PropTypes from "prop-types";

const Button = ({ label }) => <button className="Button">{label}</button>;

Button.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Button;
