import React from "react";
import "./App.css";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/images/youtube.svg";
import Button from "../Button";
import SocialLink from "../SocialLink";

const App = () => {
  return (
    <div className="App">
      <div className="Overlay" />
      <section className="Wrapper">
        <header>
          <Logo className="Logo" />
          <div className="SocialWrapper">
            <SocialLink
              icon={<InstagramIcon />}
              href="https://www.instagram.com/c3kazo/"
            />
            <SocialLink
              icon={<FacebookIcon />}
              href="https://www.facebook.com/c3kazo"
            />
            <SocialLink
              icon={<TwitterIcon />}
              href="https://twitter.com/c3kazo"
            />
            <SocialLink
              icon={<YouTubeIcon />}
              href="https://www.youtube.com/channel/UCJwdCmlxdgN2foXff2wOZKQ"
            />
          </div>
        </header>
        <main>
          <div className="Name">kazo</div>
          <div className="ButtonWrapper">
            <a href="http://booking.c3kazo.org" target="_NEW">
              <Button label="join us" />
            </a>
          </div>
        </main>
        <footer>
          <a href="tel:+256 752 459329">+256 752 459329</a>
          <a href="tel:+256 782 596572">+256 782 596572</a>
        </footer>
      </section>
    </div>
  );
};

export default App;
